@font-face {
  font-family: "Inter";
  src: local("Inter Regular"), local("Inter-Regular"), url("./font/inter/Inter-Regular.woff2") format("woff2"),
    url("./font/inter/Inter-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: local("Inter Bold"), local("Inter-Bold"), url("./font/inter/Inter-Bold.woff2") format("woff2"),
    url("./font/inter/Inter-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: local("Inter SemiBold"), local("Inter-SemiBold"), url("./font/inter/Inter-SemiBold.woff2") format("woff2"),
    url("./font/inter/Inter-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Helvetica Neue";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("./font/helveticaneue/helveticaneue.woff2") format("woff2");
}

.loadingOverlay {
  height: 100%; /* changed for demo */
  width: 100%;
  position: fixed;
  z-index: 1040;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.1);
  overflow-y: hidden;
  transition: 1s;
}
.loadingOverlayContent {
  width: 40px;
  height: 40px;
  left: 50%;
  top: 45%;
  position: relative;
  color: white;
}

:root {
  --font-base: "Inter", sans-serif;
}

body {
  font-family: var(--font-base);
}

.custom-toast {
  background-color: red;
}
.Toastify {
  &__toast {
    display: flex;
    align-items: center;
    border-radius: 12px;
    padding: 0px 24px;
  }
  &__close-button {
    height: 24px;
    color: #979fa9;
    align-self: center;
  }
  &__toast-body {
    margin: 0;
    font-size: 14px;
    font-weight: 600;
    color: #27303a;
  }
  &__toast--success {
    background-color: #f6fff9;
    border: 1px solid #48c1b5;
  }
  &__toast--success::before {
    content: "";
    display: inline-block;
    width: 21px;
    height: 21px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("/images/notify/toast_success.svg");
    margin-right: 8px;
  }
  &__toast--error {
    background-color: #f5dfe2;
    border: 1px solid #ff4e64;
  }
  &__toast--error::before {
    content: "";
    display: inline-block;
    width: 21px;
    height: 21px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("/images/notify/toast_error.svg");
    margin-right: 8px;
  }
}

input:focus,
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  outline: none !important;
  outline-offset: 0px !important;
  border: 1px solid #357e81 !important;
  border-radius: 8px !important;
  box-shadow: none !important;
}

.custom-editor {
  position: relative;
  min-height: 200px;
  background: white;
}

.custom-editor .ql-container {
  height: calc(100% - 42px) !important; /* Force height calculation */
  overflow-y: auto;
}

.editor-resize-handle {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 10px;
  cursor: row-resize;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f8f8f8;
  border: 1px solid #ddd;
  user-select: none; /* Prevent text selection while dragging */
}

.resize-line {
  width: 30px;
  height: 5px;
  background: #ddd;
  border-radius: 2px;
}

/* Optional: Add hover effect */
.editor-resize-handle:hover .resize-line {
  background: #357e81;
}
